.state {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.board {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.level {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.levelPrefix {
  font-size: 2.5em;
  font-weight: 800;
  color: gray;
  margin-right: 20px;
}
.levelTitle {
  font-size: 2.5em;
  font-weight: 800;
  color: #00b8a9;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-15px);
  }
}

.levelState {
  font-size: 1.5em;
  font-weight: 800;
  color: #00b8a9;
}

.helpNext {
  margin-left: 20px;
  font-size: 1em;
  color: gray;
  animation: bounce 0.35s ease infinite alternate;
}

.help {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  color: gray;
}
.descriptions {
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instructions {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.element {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  margin: 1px;
}

.element.empty {
  background-color: white;
}

.element.wall {
  background-image: url("../assets/wall.png");
  background-size: 100%;
}

.element.objective {
  background-image: url("../assets/key.svg");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
}

.element.box {
  background-image: url("../assets/box-closed.png");
  background-size: 100%;
}

.element.boxjective {
  background-image: url("../assets/box-opened.png");
  background-size: 100%;
}

.element.player .element.playerJective {
  background-color: white;
}

.playerup {
  background-image: url("../assets/up.png");
  background-size: 100%;
}

.playerdown {
  background-image: url("../assets/down.png");
  background-size: 100%;
}

.playerleft {
  background-image: url("../assets/left.png");
  background-size: 100%;
}

.playerright {
  background-image: url("../assets/right.png");
  background-size: 100%;
}
